<template>
  <div class="flex flex1 height_100p">
    <Sidebar  showTag :tagList="tagList" @searchList="searchListOfKeyword" @changeTags="searchListOfTags" @showUploadMedia="showUploadMedia" ></Sidebar>
    <div class="media-container flex1" @click="isExpand = false">
      <div class="media-category">
        <button v-for="item in categories" :key="item.type" 
          @click.stop="tapCate(item.type)"
          class="category-btn pointer" :class="{ 'active': currentCate === item.type }" >{{item.name}}</button>
      </div>
      <div>
        <el-upload
          v-show="fileListLen === 0"
          class="upload-hide"
          ref="uploadFile"
          name="file"
          :headers="headers"
          :action="https + '/api/V1/upload/file'"
          :data="extraParams"
          :multiple="false"
          :auto-upload="dialogSet.actionType==='edit'?true:false"
          :show-file-list="false"
          :on-progress="uploadProgress"
          :on-change="uploadChange"
          :on-success="uploadSuccess"
          :file-list="fileList">
          <button size="small" type="primary" class="dialog-form-upload flex_acenter flex_center flex fontsize_12 color_222 pointer"><i class="icon-course-add"></i><span class="padding_left8">上传文件</span></button>
        </el-upload>
      </div>
      <div 
        class="media-list flex flexwrap scroll-box" 
        :class="{'flex_acenter':listLen <= 0, 'flex_contentstart':listLen > 0}"
        v-infinite-scroll="loadData" 
        infinite-scroll-distance="100">
        <div v-if="uploadList.status === 'loading'" class="media-list-item upload-progress-item">
          <div class="media-list-card">
            <div class="media-image">
              <div class="media-progress">
                <div class="media-progress-inner" :style="{'width':uploadList.percent}"></div>
              </div>
            </div>
            <div class="margin_top5">
              <div class="tips-wrap">
                <div class="tips-txt fontsize_14 color_222 pover" style="width:90px;">{{uploadList.name}}</div>
                <div :title="uploadList.name" class="tips-title fontsize_14" style="width:90px;">{{uploadList.name}}</div>
              </div>
              <div class="flex flex_acenter padding_top6 lineheight_18 fontsize_12 color_A1A1A1">
                <i :class="[ listIconClass[uploadList.type]  ]" class="fontsize_12 color_A1A1A1"></i>
                <span class="padding_left8">{{uploadList.typeName}}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-for="(litem, index) in list" :key="litem.id" class="media-list-item prel" :class="{'active': index == currentPreview}" @click.stop="tapMedia(index,litem)">
          <!-- <el-checkbox v-model="litem.checked" :label="litem.id" class="media-list-checkbox"></el-checkbox> -->
          <i class="icon-multiple media-list-multiple fontsize_20" :class="{'checked': index == currentPreview}"></i>
          <div class="media-list-card">
            <el-image :src="litem.cover" fit="cover" lazy class="media-image"></el-image>
            <div class="margin_top10">
              <div class="tips-wrap">
                <div class="tips-txt fontsize_14 color_222 pover" style="width:140px;">{{litem.name+'.'+litem.ext}}</div>
                <div :title="litem.name+'.'+litem.ext" class="tips-title fontsize_14" style="width:140px;">{{litem.name+'.'+litem.ext}}</div>
              </div>
              <div class="flex flex_acenter padding_top5 fontsize_12 color_999">
                <i :class="[ listIconClass[litem.type]  ]" class="fontsize_12 color_999"></i>
                <span class="padding_left6 color_A1A1A1">{{litem.typeName}}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="listLen <= 0" class="nodata" style="height:500px;">
          <img v-if="keyword != ''" src="@/assets/nodata/course_search_nodata.svg" alt="" class="statu_img" loading="lazy">
          <img v-else src="@/assets/nodata/couse_nodata.svg" alt="" class="statu_img" loading="lazy">
          <span class="margin_top16 fontsize_12 color_A1A1A1">{{ keyword != '' ? '无搜索内容' : '暂无媒体'}}</span>
        </div>
      </div>
    </div>
    <div class="media-preview" :class="{ 'show': isExpand  }">
      <div class="media-preview-inner flex flexcolumn flex_acenter" v-if="isExpand">
        <div class="media-preview-cover-outer prel margin_bot16">
          <el-image class="media-preview-cover" :src="previewDetail.cover" fit="cover" lazy></el-image>
          <div class="media-preview-cover-mask flex flex_acenter flex_center pointer" @click="handlePicturePreview">
            <div class="padding_10">
              <i class="fontsize_48" :class="{
                  'icon-picture-enlarge':previewDetail.type === 'picture' || previewDetail.type === 'other',
                  'icon-media-paly':previewDetail.type === 'video' || previewDetail.type === 'audio',
              }"></i>
            </div>
          </div>
        </div>
        <p class="fontsize_16 color_222 breakword padding_0_15">{{previewDetail.name+'.'+previewDetail.ext}}</p>
        <p class="fontsize_14 color_A1A1A1 margin_top40">大小：{{previewDetail.filesize_string}}</p>
        <p class="fontsize_14 color_A1A1A1 margin_top13" v-if="previewDetail.type === 'picture' && previewDetail.width || previewDetail.type === 'video' && previewDetail.width">尺寸：{{previewDetail.width}}*{{previewDetail.height}}</p>
        <div class="margin_top40">
          <button @click="showDialogEdit(previewDetail)" class="media-edit margin_bot16 pointer">编辑</button>
          <button v-if="previewDetail.is_common != 1" @click="showDialogDel(previewDetail)" class="media-del pointer">{{$t('lang.delete')}}</button>
        </div>
      </div>
    </div>

    <fd-dialog
      :title="delDialogDetail.title"
      :visible.sync="delVisible"
      width="600px"
      :before-close="(arg)=>beforeCloseDialog(arg,'del')"
      custom-class="course-dialog not-full">
      <p class="fontsize_16 color_222 textcenter margin_bot16">请确认是否删除这个文件</p>
      <p class="fontsize_14 color_E74362 textcenter">您确认要删除文件吗？删除之后将无法找回</p>
      <span slot="footer" class="dialog-footer">
        <button type="primary" @click="delMedia(delDialogDetail.item)" class="course-save pointer">确定删除</button>
        <button @click="delVisible = false" class="course-close pointer">不删了</button>
      </span>
    </fd-dialog>
    <fd-dialog
      v-if="dialogSet"
      :title="dialogSet.title"
      :visible.sync="editVisible"
      width="1036px"
      :before-close="(arg)=>beforeCloseDialog(arg,'edit')"
      custom-class="course-dialog full">
      <el-form @submit.native.prevent>
        <div v-for="ditem in dialogSet.fieldList" :key="ditem.id" class="dialog-form-item">
          <p class="margin_bot8 fontsize_14 color_222"><span>{{ditem.label}}</span><span class="padding_left4 color_D0021B" v-if="ditem.type === 'uploadFile'">*</span></p>
          <el-input v-if="ditem.type === 'text'"
            v-model="othersParams[ditem.propsName]" autocomplete="off" :placeholder="ditem.placeholder" class="media-edit-input noborder"></el-input>
          <div v-else-if="ditem.type === 'selectAllowCreate'" class="prel">
            <el-select 
              v-model="othersParams[ditem.propsName]"
              value-key="tag_name"
              multiple
              filterable
              remote
              allow-create
              default-first-option
              :placeholder="ditem.placeholder"
              :remote-method="remoteTags"
              @change="changeSelectTag($event,ditem.propsName)"
              class="select-allow-create noborder width_100p upload-new-select">
              <el-option
                v-for="item in fileTagList"
                :key="item.id"
                :label="item.tag_name"
                :value="item.tag_name">
              </el-option>
            </el-select>
            <span  v-if="oldTag.length" class="clear-tags pointer el-select__caret el-input__icon el-icon-circle-close" @click.stop="clearTags"></span>
          </div>
          <div v-else-if="ditem.type === 'uploadFile'">
            <!-- <el-upload
              v-show="fileListLen === 0"
              class="upload-demo"
              ref="uploadFile"
              name="file"
              :headers="headers"
              :action="'/api/V1/upload/file'"
              :data="extraParams"
              :multiple="false"
              :auto-upload="dialogSet.actionType==='edit'?true:false"
              :show-file-list="false"
              :on-progress="uploadProgress"
              :on-change="uploadChange"
              :on-success="uploadSuccess"
              :file-list="fileList">
              <button size="small" type="primary" class="dialog-form-upload flex_acenter flex_center flex fontsize_12 color_222 pointer"><i class="icon-course-add"></i><span class="padding_left8">上传文件</span></button>
            </el-upload> -->
            <button v-show="fileListLen === 0"  @click="tapUpload" 
              size="small" type="primary" 
              class="dialog-form-upload flex_acenter flex_center flex fontsize_12 color_222 pointer">
              <i  class="icon-course-add"></i>
              <!-- <i v-if="uploadList.status === 'loading'" class="el-icon-loading"></i> -->
              <span class="padding_left8">上传文件</span>
            </button>
            <div v-show="fileListLen !== 0">
              <div v-for="fitem in fileList" :key="fitem.id" class="upload-list">
                <span class="fontsize_14 color_4A90E2">{{fitem.original_name || fitem.name}}</span>
                <i  v-if="dialogSet.actionType==='add'" class="icon-remove-picture margin_left16 fontsize_16 pointer remove-picture" @click="clearUploadFile"></i>
              </div>
            </div>
          </div>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <button @click="saveMedia" class="course-save pointer">保存</button>
        <button @click="closeDialogEdit" class="course-close pointer">关闭</button>
      </span>
    </fd-dialog>
    <fd-dialog
      v-if="mediaResourceSet"
      :title="mediaResourceSet.title"
      :visible.sync="mediaResourceSet.visible"
      width="1036px"
      destroy-on-close
      :before-close="(arg)=>beforeCloseDialog(arg,'resource')"
      custom-class="course-dialog full">
      <el-form @submit.native.prevent class="flex">
        <div v-if="mediaResourceSet.mediaType != 'picture'" 
          :class="{ 'padding_top38': mediaResourceSet.mediaType==='video' || mediaResourceSet.mediaType==='audio' }"
          class="media-resource-area flex flexcolumn flex_jcbetween">
          <audio v-if="mediaResourceSet.mediaType === 'audio'" controls controlslist="nodownload"
            ref="videoPlayer"
            :src="othersParams.url" 
            @ended="endplay" @timeupdate="timeupdate" 
            @loadedmetadata="vloadedmetadata" @play="vplay" @pause="vpause"
            style="width:0;height:0;visibility:hidden;"
            class="">
            您的浏览器不支持 audio 标签。
          </audio>
          <video v-if="mediaResourceSet.mediaType === 'video'" 
            ref="videoPlayer" controls
            controlslist="nodownload"
            :src="othersParams.url" 
            :poster="othersParams.cover"
            @error="errormsg"
            @ended="endplay" @timeupdate="timeupdate" 
            @loadedmetadata="vloadedmetadata" @play="vplay" @pause="vpause"
            style="width: 100%; height: 100%; object-fit: fill"
            class="margin_top16">
          </video>
          <div v-else class="flex flex_acenter flex_center flexcolumn">
            <img  v-if="othersParams.cover" :src="othersParams.cover" fit="cover" class="media-resource-area-img" loading="lazy" />
            <p class="fontsize_16 color_222 margin_top8">{{othersParams.name}}</p>
            <p class="fontsize_14 color_222 margin_top24" v-if="othersParams.description">{{othersParams.description}}</p>
            <p class="fontsize_12 color_A1A1A1 margin_top40">大小：{{othersParams.filesize_string}}</p>
          </div>
          <div v-if="mediaResourceSet.mediaType==='video' || mediaResourceSet.mediaType==='audio'" 
            class="media-resource-area-bottom flex flex_acenter" >
            <i 
              @click="toPlay"
              :class="{
              'icon-pause-white': !mediaPlay.paused,
              'icon-play-gray': mediaPlay.paused}" class="flexshrink icon_color_A1A1A1 fontsize_24 pointer" style="width:20px;"></i>
            <div v-if="mediaResourceSet.mediaType==='video' || mediaResourceSet.mediaType==='audio'" class="flex1 flex flex_acenter media-video">
              <el-slider v-model="mediaPlay.currentTime" :max="100" :format-tooltip="formatTooltip" class="width_100p" @change="changeSlider"></el-slider>
              <span class="flexshrink fontsize_14 color_A1A1A1 padding_left8">{{duration_str}}</span>
            </div>
          </div>
        </div>
        <div v-else-if="mediaResourceSet.mediaType === 'picture'" class="media-resource-cropper flex flexcolumn flex_jcbetween">
          <div class="media-resource-cropper-wrapper">
            <img  v-if="othersParams.url" :src="othersParams.url" fit="cover" class="media-resource-cropper-img" id="cropper-img" />
          </div>
          <div  v-if="cropperData.cropperTools[0].currentStatus === 'white'" class="media-resource-cropper-done">
            <span  @click="doneCrop" class="btn done">完成</span>
            <span @click="resetCrop" class="btn cancel">取消</span>
          </div>
          <div class="media-resource-cropper-tools">
            <div v-for="(item,index) in cropperData.cropperTools" :key="item.type"  
              @click="tapCropperTools(item,index)" 
              :class="{'active': item.currentStatus === 'white', 'disabled': operateLogLen === 0 && ( item.type === 'backout') }"
              class="item pointer flex flex_acenter flex_center flexcolumn" >
              <i :class="[item.iconClass[item.currentStatus]]" class="fontsize_20 cropper-icon"></i>
              <span class="fontsize_12 color_222 margin_top6 cropper-name">{{item.name}}</span>
            </div>
          </div>
        </div>
        <div class="media-resource-input flex1">
          <div v-for="ditem in mediaResourceSetFieldList" :key="ditem.id" class="dialog-form-item">
            <p class="margin_bot8 fontsize_14 color_222"><span>{{ditem.label}}</span><span class="padding_left4 color_D0021B" v-if="ditem.type === 'uploadFile'">*</span></p>
            <p v-if="ditem.tips" class="margin_bot8 fontsize_12 color_A1A1A1">{{ditem.tips}}</p>
            <el-input v-if="ditem.type === 'text'"
              v-model="othersParams[ditem.propsName]" autocomplete="off" :placeholder="ditem.placeholder" class="media-edit-input height36 noborder"
              :disabled="othersParams.is_common == 1"></el-input>
            <div v-else-if="ditem.type === 'selectAllowCreate'" class="prel">
              <el-select 
                :disabled="othersParams.is_common == 1"
                v-model="othersParams[ditem.propsName]"
                value-key="tag_name"
                multiple
                filterable
                remote
                allow-create
                default-first-option
                :placeholder="ditem.placeholder"
                :remote-method="remoteTags"
                @change="changeSelectTag($event,ditem.propsName)"
                class=" noborder width_100p select-allow-create">
                <el-option
                  v-for="item in fileTagList"
                  :key="item.id"
                  :label="item.tag_name"
                  :value="item.tag_name">
                </el-option>
              </el-select>
              <span  v-if="oldTag.length" class="clear-tags pointer el-select__caret el-input__icon el-icon-circle-close" @click.stop="clearTags"></span>
            </div>
            <div v-else-if="ditem.type === 'uploadFile'">
              <el-upload
                v-show="fileListLen === 0"
                class="upload-demo"
                ref="uploadFile"
                name="file"
                :headers="headers"
                :action="https + '/api/V1/upload/file'"
                :data="extraParams"
                :multiple="false"
                :auto-upload="mediaResourceSet.actionType==='edit'?true:false"
                :show-file-list="false"
                :on-change="uploadChange"
                :on-success="uploadSuccess"
                :file-list="fileList">
                <button size="small" type="primary" class="dialog-form-upload flex_acenter flex_center flex fontsize_12 color_222 pointer"><i class="icon-course-add"></i><span class="padding_left8">上传文件</span></button>
              </el-upload>
              <div v-show="fileListLen !== 0">
                <div v-for="fitem in fileList" :key="fitem.id" class="upload-list">
                  <span class="fontsize_14 color_4A90E2">{{fitem.original_name || fitem.name}}</span>
                  <i  v-if="mediaResourceSet.actionType==='add'" class="icon-remove-picture margin_left16 fontsize_16 pointer remove-picture" @click="clearUploadFile"></i>
                </div>
              </div>
            </div>
            <div v-else-if="ditem.type === 'picture_size'" class="flex flex_acenter">
              <div v-for="(pitem, pind) in ditem.propArr" :key="pitem.propsName" class="flex flex_acenter">
                <el-input @input="changePic($event,pitem.propsName)" v-model="othersParams[pitem.propsName]" :min="0" type="number" autocomplete="off" 
                :placeholder="pitem.placeholder" class="media-edit-input height36 noborder"></el-input>
                <span v-if="pind !== ditem.propArr.length - 1" class="fontsize_24 color_A1A1A1 padding_0_10"  >:</span>
              </div>
            </div>
            <div v-else-if="ditem.type === 'picture_selectarea'"  class="flex flex_acenter">
              <div v-for="(pitem, pind) in ditem.propArr" :key="pitem.propsName" class="flex flex_acenter">
                <el-input @input="changePic($event,pitem.propsName)" v-model="othersParams[pitem.propsName]" :min="0" type="number" autocomplete="off" 
                :placeholder="pitem.placeholder" class="media-edit-input height36 noborder"></el-input>
                <span v-if="pind !== ditem.propArr.length - 1" class="fontsize_24 color_A1A1A1 padding_0_10"  >:</span>
              </div>
            </div>
          </div>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <button @click="editMedia" class="course-save pointer">保存</button>
        <button @click="closeDialogEdit('resource')" class="course-close pointer" style="background:#A1A1A1;">关闭</button>
      </span>
    </fd-dialog>
    <fd-dialog 
      :visible.sync="dialogPreview.visible" 
      :modal-append-to-body="true" 
      :append-to-body="true"
      custom-class="preview-resource-dialog">
        <el-image :src="dialogPreview.url" fit="contain" style="object-fit:contain;height:80vh;width:100%;" lazy ></el-image>
    </fd-dialog>
  </div>
</template>

<script>
import 'cropperjs/dist/cropper.css';
import Cropper from 'cropperjs';
import resourcePic from '@/assets/media/word@2x.png';
import { mapActions, mapState, mapMutations } from 'vuex';
import Config from '@/libs/config';
import { getToken } from '@/libs/utils/auth';
import { debounce, dataURLtoFile } from '@/libs/utils/tools';
import { delMediaFile, updateMediaFile, addMediaTag, uploadSingleFile } from '@/libs/api/media';
import { Sidebar } from '@/renderer/components/Dashboard/components';
import progressExample from "@/renderer/components/common/progressExample.vue";
export default {
  components: {
    Sidebar,
    progressExample
  },
  data() {
    return {
      https:process.env.VUE_APP_API,
      resourceImg: resourcePic,
      token:"",
      keyword:"",
      tag_name_list:[],
      limit:50,
      page:1,
      total:0,
      categories: [
        {
          name:'全部',
          type:'all'
        },
        {
          name:'图像',
          type:'picture'
        },
        {
          name:'音频',
          type:'audio'
        },
        {
          name:'视频',
          type:'video'
        },
        {
          name:'其他',
          type:'other'
        }
      ],
      currentCate: "all", //当前选中分类
      listIconClass:{
        "picture": "icon-media-image",
        "video": "icon-media-video",
        "audio": "icon-media-audio",
        "other": "icon-media-others",
      },
      list:[],
      tagList:[],
      fileTagList:[],
      previewDetail:{},
      currentPreview: -1, //当前预览媒体文件
      isExpand: false, //是否展开右侧预览区域
      delDialogDetail:{ //删除弹窗
        title:"",
        placeholder: "",
        label:"",
        item: {}
      },
      delVisible: false, //删除-确认弹窗
      editVisible:false, //编辑弹窗、添加弹窗
      dialogSet: { 
        title:"编辑媒体",
        visible:false,
        actionType:"edit", //edit add
        fieldList: [
          {
            id: 201,
            type: "uploadFile",
            label:"文件",
            uploadtext: "上传文件",
          },
          {
            id: 202,
            type: "text",
            label:"文件名称",
            placeholder: "请输入文件名称",
            propsName:"name"
          },
          {
            id: 203,
            type: "text",
            label:"文件描述",
            placeholder: "请输入文件描述",
            propsName:"description"
          },
          {
            id: 204,
            type: "selectAllowCreate",
            label:"文件标签",
            placeholder: "请输入标签，回车建立标签",
            propsName:"tag_name_list"
          }
        ],
      },
      mediaResourceSet: { 
        title:"编辑媒体",
        visible:false,
        actionType:"edit", //edit add
        fieldList: [
          {
            id: 302,
            type: "text",
            label:"文件名称",
            placeholder: "请输入文件名称",
            propsName:"name"
          },
          {
            id: 303,
            type: "text",
            label:"文件描述",
            placeholder: "请输入文件描述",
            propsName:"description"
          },
          {
            id: 304,
            type: "selectAllowCreate",
            label:"文件标签",
            placeholder: "请输入标签，回车建立标签",
            propsName:"tag_name_list"
          },
          {
            id: 305,
            type: "picture_size",
            label:"尺寸",
            tips: "成比例修改图片大小",
            placeholder: "请输入数值",
            propsName:"size",
            propArr:[
              {
                propsName:"ratioW",
                placeholder: "请输入数值",
              },
              {
                propsName:"ratioH",
                placeholder: "请输入数值",
              }
            ],
          },
          {
            id: 306,
            type: "picture_selectarea",
            label:"选区",
            tips:"输入像素来调整裁剪的选区大小",
            placeholder: "请输入数值",
            propsName:"selectarea",
            propArr:[
              {
                propsName:"width",
                placeholder: "请输入数值",
              },
              {
                propsName:"height",
                placeholder: "请输入数值",
              }
            ],
          }
        ],
        mediaType:'',
      },
      othersParams: {
        name:"",
        description:"",
        tag_name_list:[], //标签数组
      },
      oldTag:[], //标签选中
      fileList: [], //媒体文件上传
      cropperData: {
        element:null,
        initDeatil: null, //首次进入时候的裁切数据
        detail:{},
        backUrl:"",
        operateLog: [], //操作记录
        cropperTools:[
          {
            name: "裁剪",
            type: "cutting",
            action: "",
            iconClass: {
              default: "icon-cropper-cutting-default",
              white:"icon-cropper-cutting-white",
              active:"icon-cropper-cutting-active"
            },
            currentStatus:'default'
          },
          {
            name: "向左转",
            type: "turnLeft",
            action: "",
            iconClass: {
              default: "icon-cropper-turn-left-default",
              white:"icon-cropper-turn-left-white",
              active:"icon-cropper-turn-left-active"
            },
            currentStatus:'default'
          },
          {
            name: "向右转",
            type: "turnRight",
            action: "",
            iconClass: {
              default: "icon-icropper-turn-right-default",
              white:"icon-cropper-turn-right-white",
              active:"icon-cropper-turn-right-active"
            },
            currentStatus:'default'
          },
          {
            name: "垂直翻转",
            type: "flipVertical",
            action: "",
            iconClass: {
              default: "icon-cropper-flip-vertical-default",
              white:"icon-cropper-flip-vertical-white",
              active:"icon-cropper-flip-vertical-active"
            },
            currentStatus:'default'
          },
          {
            name: "水平翻转",
            type: "flipHorizintal",
            action: "",
            iconClass: {
              default: "icon-cropper-flip-horizintal-default",
              white:"icon-cropper-flip-horizintal-white",
              active:"icon-cropper-flip-horizintal-active"
            },
            currentStatus:'default'
          },
          {
            name: "撤销",
            type: "backout",
            action: "",
            iconClass: {
              default: "icon-cropper-backout-default",
              white:"icon-cropper-backout-white",
              active:"icon-cropper-backout-active"
            },
            currentStatus:'default'
          },
          {
            name: "重做",
            type: "redo",
            action: "",
            iconClass: {
              default: "icon-cropper-redo-default",
              white:"icon-cropper-redo-white",
              active:"icon-cropper-redo-active"
            },
            currentStatus:'default'
          },
        ]
      },
      mediaPlay: {
        paused: true, //false 播放，true暂停
        currentTime: 0,
        duration: 0, //时长
      },
      dialogPreview: {//图片预览
        visible: false,
        url: ""
      },
      uploadList: {
        0:{
          percent:0,
          name:"",
          type:"",
        },
        status: -1, //"loading"、"success" 、"fail"
        percent:0,
        name:"",
        type:"",
        length:0
      },
    };
  },
  computed: {
    ...mapState({
      is_web: state => state.app.isWebSide,
    }),
    headers() {
      return {
        Authorization : 'Bearer ' + this.token
      }
    },
    duration_str() {
      //时间
      if(this.mediaResourceSet.mediaType !== 'audio' && this.mediaResourceSet.mediaType !== 'video') return "";
      let timeStr = this.othersParams.duration_string || "";
      timeStr = timeStr.replace("时",":").replace("分",":").replace("秒","");
      return timeStr;
    },
    fileListLen() {
      return this.fileList && this.fileList.length || 0;
    },
    // 额外参数用于 上传新媒体
    extraParams() {
      if(this.dialogSet.actionType === 'add') {
        return {
          name: this.othersParams.name,
          description: this.othersParams.description,
          tag_name_list: this.othersParams.tag_name_list,
        }
      }else {
        return {}
      }
    },
    operateLogLen() { //操作记录
      return this.cropperData.operateLog && this.cropperData.operateLog.length || 0
    },
    mediaResourceSetFieldList() {
      if(this.mediaResourceSet.mediaType === 'picture') {
        return this.mediaResourceSet.fieldList;
      }else {
        return this.mediaResourceSet.fieldList.filter(v => v.type.indexOf('picture_') === -1)
      }
    },
    listLen() {
      return this.list.length || 0
    },
  },
  async mounted() {
    const token = await getToken();
    this.token = token;

    this.keyword="";
    this.tag_name_list=[];
    this.page=1;
    this.list=[];
    this.getList();
    this.getTagList();
  },
  beforeDestroy() {
    this.cropperData.element && this.cropperData.element.destroy();
  },
  methods: {
    ...mapActions(['GetMediaList','GetMediaTagList']),
    tapUpload() {
      // 点击上传
      // if(this.uploadList.status === 'loading') {
      //   return;
      // }
      this.$refs.uploadFile && this.$refs.uploadFile.$children[0].$el.click();
    },
    loadData() {
      // 加载下一页
      if(this.page * this.limit >= this.total) return;
      this.page++;
      this.getList();
    },
    getList() {
      // 获取媒体列表
      this.GetMediaList({
        type: this.currentCate,
        keyword: this.keyword,
        tag_name_list: this.tag_name_list,
        limit: this.limit,
        page: this.page
      }).then(res => {
        if(res.errorcode == 0) {
          this.total = res.data.total || 0;
          res.data.data && res.data.data.map(v => {
            if(v.url && v.url.indexOf('http') == -1) {
              v.url = Config.server + v.url;
            }
            switch(v.type) {
              case "picture":
                v.typeName = "图像";
                break;
              case "video":
                v.typeName = "视频";
                break;
              case "audio":
                v.typeName = "音频";
                break;
              case "other":
              default:
                v.typeName = "其他";
                break;
            }
            return v;
          });
          this.list = this.page == 1 ? (res.data.data || []) : this.list.concat(res.data.data);
          
        } else {
          this.total = -1;
        }
      }).catch(err => {
        this.total = -1;
      });
    },
    getTagList(tag_name) {
      // 获取标签列表
      this.GetMediaTagList(tag_name ? tag_name : '').then(res => {
        if(res.errorcode == 0) {
          this.tagList = res.data.data;
          this.fileTagList = res.data.data;
        }
      }).catch();
    },
    searchListOfKeyword: debounce(
      function({keyword}){
        // 通过关键词搜索
        if(this.keyword === keyword) return;
        this.keyword = keyword;
        this.page=1;
        this.list=[];
        this.getList();
      },
      Config.sidebarSearchDebounceDuration, true
    ),
    searchListOfTags(val) {
      // 通过标签搜索
      this.page=1;
      this.list=[];
      this.tag_name_list = val;
      this.getList();
    },
    handlePicturePreview() {
      console.log('previewDetail', this.previewDetail)
      // 预览图片
      let {  ext, type, url } = this.previewDetail;
      if(type !== "picture") {
        if(this.is_web) window.open(url,"_blank");
        else window.open('/#/webview?websrc='+url+`${ext.indexOf('doc') >= 0 ? '&isdownload=true' : ''}`,"_blank");
        return;
      }
      this.dialogPreview.url = url;
      this.dialogPreview.visible = true;
    },
    changeSlider(val) {
      // 拖动
      let time = (val / 100 * this.mediaPlay.duration).toFixed(2);
      
      this.$refs.videoPlayer.currentTime  = time; //同步音视频进度
    },
    formatTooltip(val) { 
      //计算对应播放时间
      return (val / 100 * this.mediaPlay.duration).toFixed(2);
    },
    toPlay() {
      console.dir(this.$refs.videoPlayer)
      let pauseStatus = this.$refs.videoPlayer.paused;
      
      if(pauseStatus) {
        // 播放
        this.$refs.videoPlayer.play();
      }else {
        // 暂停
        this.$refs.videoPlayer.pause();
      }
    },
    errormsg() {},
    endplay() {
      // console.log("endplay",this.$refs.videoPlayer.currentTime)
    },
    timeupdate() {
      this.mediaPlay.currentTime = Number( ( this.$refs.videoPlayer.currentTime / this.mediaPlay.duration *  100 ).toFixed(2) );
    },
    vloadedmetadata() {
      // console.dir(this.$refs.videoPlayer)
      this.mediaPlay.duration = this.$refs.videoPlayer.duration;
      // console.log("vloadedmetadata",this.$refs.videoPlayer.currentTime)
    },
    vplay() {
      this.mediaPlay.paused = false;
      // console.log('vplay',this.$refs.videoPlayer.currentTime)
    },
    vpause() {
      this.mediaPlay.paused = true;
      //  console.log("vpause",this.$refs.videoPlayer.currentTime)
    },
    showUploadMedia() {
      // 显示 上传媒体 弹窗
      this.fileList = [];
      this.clearUploadFile(); //清空文件列表

      this.dialogSet.title = "上传新媒体";
      this.dialogSet.actionType = "add";
      this.editVisible = true;
    },
    clearTags() {
      // 清空标签
      this.othersParams.tag_name_list = [];
      this.oldTag = [];
    },
    changeSelectTag(val, prop) {
      // 选中值发生变化
      // console.log('选中值发生变化',val,prop ,this.oldTag);
      let newVal = this.othersParams[prop].filter(v => {
        return !this.oldTag.includes(v)
      }) 
      // console.log('新增',newVal);
      this.oldTag.push(...newVal);
      newVal.forEach(v => {
        addMediaTag(v).then(res => {
          if(res.errorcode == 0) {
            this.getTagList("");
          }
        })
      } )
    },
    remoteTags(query) {
      // 远程搜索标签
      if(query === '') {
        this.fileTagList = this.tagList;
        return;
      }
      this.GetMediaTagList(query).then(res => {
        if(res.errorcode == 0) {
          this.fileTagList = res.data.data;
          this.$forceUpdate();
        }
      }).catch();
    },
    showDialogEdit(detail) {
      let { type } = detail;
      // 显示编辑弹窗
      this.clearUploadFile();
      this.fileList = [];
      this.fileList.push({ name: detail.url.substr(detail.url.lastIndexOf("/") + 1) });
      Object.assign(this.othersParams, detail);
      this.othersParams.tag_name_list = this.othersParams.tag_list.map(v => v.tag_name);
      this.oldTag = this.othersParams.tag_name_list;

      if(type === 'picture') {
        this.initCropperData();
        this.mediaResourceSet.title = "编辑媒体";
        this.mediaResourceSet.actionType = "edit";
        this.mediaResourceSet.item = detail; //作为备份
        this.fileList = [];
        this.mediaResourceSet.visible = true;
        this.mediaResourceSet.mediaType = 'picture';
        this.$nextTick(()=>{
          // 启用图片裁切
          const image = document.getElementById('cropper-img');
          const cropper = new Cropper(image, {
            width: 500,
            height: 300,
            minWidth: 563,
            minHeight: 360,
            maxWidth: 563,
            maxHeight: 360,
            imageSmoothingEnabled: false,
            imageSmoothingQuality: 'high',
            viewMode:1,
            autoCrop:false, //不默认裁剪
            // aspectRatio: 16 / 16, //选区限制比例
            // checkCrossOrigin: false, //不检查是否跨域
            crop: (event)=>{
              // 当画布（图像包装）或裁剪框发生变化时会触发此事件 ，裁切，取消裁切
              // console.log(event.detail);
              // console.log(event.detail.y);
              // console.log(event.detail.width);
              // console.log(event.detail.height);
              // console.log(event.detail.rotate);
              // console.log(event.detail.scaleX);
              // console.log(event.detail.scaleY);
              if(this.cropperData.cropperTools[0].currentStatus === "default" && event.detail.y !== 0 && event.detail.width !== 0 ) {
                // 裁切回调
                // event.detail.y !== 0 && event.detail.width !== 0 排除 crop.clear()所触发的回调
                // // 记录裁切状态
                let operate = {
                  action: "crop",
                  args: ""
                };
                this.cropperData.operateLog.push(operate);
                this.cropperData.cropperTools[0].currentStatus = "white";
              }
              this.cropperData.detail = event.detail;
              
              if(this.cropperData.initDeatil == null) {
                this.cropperData.initDeatil = event.detail;
              }
            },
            cropend: ()=>{
              let detail = this.cropperData.detail;
              if(detail.width) {
                this.othersParams.width = Number(detail.width).toFixed(2);
              }
              if(detail.height) {
                this.othersParams.height = Number(detail.height).toFixed(2);
              }
              this.$forceUpdate();
            }
          });
          this.cropperData.element = cropper;
        })
      }else {
        this.mediaResourceSet.title = "编辑媒体";
        this.mediaResourceSet.actionType = "edit";
        this.mediaResourceSet.mediaType = type;
        this.mediaResourceSet.item = detail; //作为备份
        this.fileList = [];
        this.mediaResourceSet.visible = true;
        
      }
    },
    closeDialogEdit(type) {
      //关闭 编辑弹窗
      
      // this.fileList = [];
      Object.keys(this.othersParams).forEach(v => {
        this.othersParams[v] = "";
      });
      if(type === 'resource') {
        this.cropperData.element && this.cropperData.element.destroy();
        this.mediaResourceSet.visible = false;
        return;
      }

      this.editVisible = false;
    },
    showDialogDel(item) {
      // 删除媒体文件-确认弹窗
      this.delDialogDetail.title = "删除";
      this.delDialogDetail.item = item;
      this.delVisible = true;
    },
    beforeCloseDialog(done, actionType) { 
      //弹窗关闭前 回调

      // 上传时，不清空文件，关闭弹窗仍然可以上传
      // this.fileList = [];
      // this.clearUploadFile(); //清空文件列表
      Object.keys(this.othersParams).forEach(v => {
        this.othersParams[v] = "";
      });
      done();


      // 上传弹窗
      if(actionType === 'add') {
        this.editVisible = false;
        return;
      }

      // 编辑弹窗
      if(actionType === 'edit') {
        this.editVisible = false;
        return;
      }

      if(actionType === 'resource') {
        this.cropperData.element && this.cropperData.element.destroy();
        this.mediaResourceSet.visible = false;
        return;
      }
    },
    delMedia(item) {
      // 删除媒体文件
      delMediaFile(item.id).then(res => {
        if(res.errorcode == 0) {
          // 刷新列表
          this.keyword="";
          this.tag_name_list=[];
          this.page=1;
          this.list=[];
          this.total=0;
          this.getList();
        }
        this.delVisible = false;
        // 关闭右侧预览
        this.previewDetail = {};
        this.currentPreview = -1; //当前预览媒体文件
        this.isExpand = false; //是否展开右侧预览区域
      }).catch(()=>{
        this.delVisible = false;
      });
    },
    tapMedia(index,item) {
      // 点击媒体文件
      this.list[index].checked = true;
      if(this.currentPreview !== -1) this.list[this.currentPreview].checked = false;
      this.list.forEach((v,i) => {
        if(i != index) v.checked = false;
        else v.checked = true;
      });
      this.currentPreview = index;
      this.previewDetail = JSON.parse( JSON.stringify( item ) );
      this.isExpand = true;
    },
    tapCate(cateType) {
      //请求对应分类下数据
      if(this.currentCate === cateType) return;
      this.currentCate = cateType;
      this.page=1;
      this.list=[];
      this.getList();

      this.previewDetail = {};
      this.currentPreview = -1; //当前预览媒体文件
      this.isExpand = false; 
    },
    clearUploadFile() {
      // 清空已上传的文件列表
      if(Array.isArray(this.$refs.uploadFile)) {
        this.$refs.uploadFile[0].clearFiles();
        // 文件删除后 文件名也自动删除
        this.othersParams.name = "";
      } else if(this.$refs.uploadFile){
        this.$refs.uploadFile.clearFiles();
        // 文件删除后 文件名也自动删除
        this.othersParams.name = "";
      }
      this.fileList = [];
    },
    uploadSuccess(response, file, fileList) {
      // 上传成功
      if(response.errorcode == 0) {
        // 编辑
        if(this.dialogSet.actionType === 'edit') {
          this.fileList = [];
          this.fileList.push(response.data)
        }else if(this.dialogSet.actionType === 'add') {
          // 上传
          // 上传结束
          this.uploadList.status = "success";

          for(let key in this.othersParams) {
            this.othersParams[key] = "";
          }
          this.editVisible = false;
          // 清空文件列表
          this.clearUploadFile();
        }
        // 刷新列表
        this.page=1;
        this.list=[];
        this.getList();
      }else{
        this.$message.warning(response.msg);
        this.uploadList.status = "fail";
      }
    },
    uploadChange(file,fileList) {
      // 文件选择改变- 仅用于添加新媒体
      if(this.dialogSet.actionType==='add') {
        this.fileList = fileList;
        if(this.othersParams.name === "") {
          this.othersParams.name = file.name.substr(0, file.name.lastIndexOf('.'));
        }
        this.getUploadDetail(file,fileList);
      }
    },
    getUploadDetail(file,fileList) {
      //获取选中文件详情
      let { name, uid, percentage, raw:{ type } } = file;
      
      const fileTypeSet = {
        picture: ["png", "jpg", "jpeg", "gif"],
        audio: ["mp3","wav","mpga"],
        video: ["mp4","ogg"],
      };
      const fileTypeName = {
        picture:"图片",
        audio:"音频",
        video:"视频",
      };
      let ext = name.indexOf(".") && name.split(".")[name.split(".").length-1]  || "";
      let fileTypeArr = Object.keys(fileTypeSet);
      // console.log(name,ext);
      let typeIndex = -1;
      fileTypeArr.findIndex( v => {
        if(fileTypeSet[v].indexOf(ext) > -1){
          return typeIndex = fileTypeArr.indexOf(v);
        }
      });
      this.uploadList.type = typeIndex != -1 ? fileTypeArr[typeIndex] : "other";
      if( typeIndex != -1 ) {
        this.uploadList.type = fileTypeArr[typeIndex];
        this.uploadList.typeName = fileTypeName[ fileTypeArr[typeIndex] ];
      }else {
        this.uploadList.type = "other";
        this.uploadList.typeName = "其他";
      }

      this.uploadList.name = name;
    },
    uploadProgress(event, file, fileList) {
      console.log('uploadProgress', event.percent)
      let { percent } = event;
      this.uploadList.status = "loading";
      this.uploadList.percent = percent + "%";
    },
    saveMedia() {
      // 编辑/上传 媒体文件
      let type = this.dialogSet.actionType;
      switch(type) {
        case "edit":
          this.updateMedia();
          break;
        case "add":
          this.uploadNewMedia();
          break;
      }
    },
    updateMedia() {
      // 编辑媒体文件
      let { id, name, description, tag_name_list } = this.othersParams;
      // console.log(id, name, description, tag_name_list);
      updateMediaFile({id, name, description, tag_name_list}).then(res => {
        if(res.errorcode == 0) {
          this.mediaResourceSet.visible = false;
          this.currentPreview = -1;
          this.previewDetail = {};
          this.isExpand = false;
          this.page=1;
          this.list=[];
          this.getList();
          
        }
      });
    },
    uploadNewMedia() {
      // 上传单个文件
      if(this.fileList.length == 0) {
        this.$message("请上传文件！");
        return;
      }
      // 参数处理
      for(let i in this.extraParams.tag_name_list) {
        this.extraParams[`tag_name_list[${i}]`] = this.extraParams.tag_name_list[i];
      }
      // 上传开始
      this.uploadList.status = "loading";

      if(Array.isArray(this.$refs.uploadFile)) {
        this.$refs.uploadFile[0].submit();
      } else {
        this.$refs.uploadFile.submit();
      }
    },
    initCropperData() {
      // 初始化Cropper部分数据
      this.cropperData.operateLog = [];
      this.cropperData.cropperTools.forEach(v => {
        v.currentStatus = "default";
      });
    },
    tapCropperTools(item,index) {
      //裁切类型
      // console.log('tapCropperTools',item,index)
      let { type, url } = item;
      let {height, width, x, y, rotate, scaleX, scaleY, } = this.cropperData.detail;
      let status = index >= 0 ? this.cropperData.cropperTools[index].currentStatus : null;
      let cropper = this.cropperData.element;
      //记录操作详情
      let operate = {
        action:"",
        args:""
      };
      if(type === "cutting") { 
        this.cropperData.cropperTools[index].currentStatus = status != "white" ? "white" : "default";
      }
      switch(type) {
        case "cutting":
          // 裁剪
          if(status !== 'white') {
            cropper.crop();
            operate.action = "crop";
          }else {
            cropper.clear();
            operate.action = "clear";
          }
          break;
        case "turnLeft":
          // 向左转
          cropper.rotate(-90);
          operate.action = "rotate";
          operate.args = "-90";
          break;
        case "turnRight":
          cropper.rotate(90);
          operate.action = "rotate";
          operate.args = "90";
          // 向右转
          break;
        case "flipVertical":
          // 垂直翻转
          cropper.scaleY( scaleY == -1 ? 1 : -1);
          operate.action = "scaleY";
          operate.args = scaleY == -1 ? 1 : -1;
          break;
        case "flipHorizintal":
          // 水平翻转
          cropper.scaleX( scaleX == -1 ? 1 : -1);
          operate.action = "scaleX";
          operate.args = scaleX == -1 ? 1 : -1;
          break;
        case "replace":
          // console.log('替换', url)
          cropper.replace(url);
          operate.action = "replace";
          operate.args = url;
          operate.lastUrl = this.cropperData.backUrl || this.mediaResourceSet.item.url;
          this.cropperData.backUrl = url;
          break;
        case "backout":
          // 撤销
          let logs = this.cropperData.operateLog;
          let last = logs[logs.length-1]; //最后一个操作
          if(logs.length >= 1) {
            // 最后一个操作
            this.toBackOut(last, logs.length-1);
            this.cropperData.operateLog.pop();
          }
          break;
        case "redo":
          // 重做 - 撤销所有操作 ，绑定数据值恢复
          // cropper.reset();
          this.cropperData.operateLog = [];
          operate.action = "reset";
          operate.args = "";
          cropper.clear();
          this.cropperData.cropperTools[0].currentStatus = "default";
          this.cropperData.initDeatil && cropper.setData(this.cropperData.initDeatil);
          // cropper.setAspectRatio(1); //恢复宽高比
          if(this.cropperData.backUrl) { //恢复原图
            cropper.replace(this.mediaResourceSet.item.url);
          }
          break;
      }
      if(type !== "backout" && type !== "redo"){
        this.cropperData.operateLog.push(operate)
      }
    },
    toBackOut(item ,index) {
      //撤销操作
      let { action, args, lastUrl } = item;
      let status = index >= 0 ? this.cropperData.cropperTools[index].currentStatus : null;
      let cropper = this.cropperData.element;
      let backOperate = {
        action: "",
        args: ""
      };
      switch( action ) {
        case "rotate":
          // 左右转
          backOperate.action = "rotate";
          backOperate.args = args == "90" ? -90 : 90;
          break;
        case "scaleY":
          // 垂直翻转
          backOperate.action = "scaleY";
          backOperate.args = args == 1 ? -1 : 1;
          break;
        case "scaleX":
          // 水平翻转
          backOperate.action = "scaleX";
          backOperate.args = args == 1 ? -1 : 1;
          break;
        case "clear":
          // 去掉裁切
          backOperate.action = "crop";
          break;
        case "crop":
          // 开始裁切
          backOperate.action = "clear";
          break;
        case "replace":
          backOperate.action = "replace";
          break;
      }
      // 重置无法撤销
      if(action === "reset") return;
      // 撤销操作
      if(action === 'replace') {
        cropper[backOperate.action]( lastUrl );
        this.cropperData.backUrl = lastUrl === this.mediaResourceSet.item.url ? "" : lastUrl;
        setTimeout(() => {
            // cropper.crop();
            // cropper.restore();
          },10)
      }else {
        cropper[backOperate.action]( backOperate.args );
      }
      // 恢复状态
      if(backOperate.action === "crop" || backOperate.action === "clear") { 
        this.cropperData.cropperTools[0].currentStatus = status != "white" ? "white" : "default";
      }
    },
    resetCrop() {
      // 取消裁剪
      this.tapCropperTools(this.cropperData.cropperTools[0], 0);
    },
    doneCrop() {
      // 完成裁剪
      let cropper = this.cropperData.element;
      let url = cropper.getCroppedCanvas().toDataURL();
      // console.log('url',url)
      this.tapCropperTools({
        url: url,
        type: "replace",
      },-1);
      setTimeout(() => {
        // this.resetCrop();
        cropper.clear();
      }, 10);
    },
    saveImage() {
      // 获取裁切后的图片
      let cropper = this.cropperData.element;
      
      let file = dataURLtoFile(cropper.getCroppedCanvas().toDataURL());
      let { id, name, description, tag_name_list } = this.othersParams;
      uploadSingleFile({
        id,
        file: file,
        name, description, tag_name_list
      }).then(res => {
        if(res.errorcode == 0) {
          this.mediaResourceSet.visible = false;
          this.currentPreview = 0;
          this.previewDetail = res.data;
          this.previewDetail.url = res.data.url ? res.data.url : res.data.path; //初始化url,upload返回是path
          this.isExpand = true;
          this.page=1;
          this.list=[];
          this.getList();
        }
      })
    },
    editMedia() {
      // 判断是否图片编辑并且编辑了图片
      if(this.mediaResourceSet.mediaType === 'picture' && this.cropperData.operateLog.length > 0) {
        this.saveImage();
        return;
      }
      //编辑保存 媒体
      this.updateMedia();
    },
    changePic(val, props) {
      // 修改图片选区、宽高比例
      let cropper = this.cropperData.element;
      this.othersParams[props] = val;
      this.$forceUpdate();
      if(val < 0) this.othersParams[props] = Math.abs(val);
      if(this.othersParams['ratioH'] && this.othersParams['ratioW']) {
        // 修改宽高比
        let ratio = this.othersParams['ratioW']/this.othersParams['ratioH'];
        cropper.setAspectRatio(ratio);
      }else {
        //恢复自由比例
        cropper.setAspectRatio(NaN);
      }
      if(props === 'width' && this.othersParams['height'] || props === 'height' && this.othersParams['width']) {
        // 修改选取宽高
        let data = cropper.getData();
        data.width = Number(this.othersParams['width']);
        data.height = Number(this.othersParams['height']);
        cropper.setData(data);
      }
    }
  },
}
</script>

<style lang="less" scoped>
  .media-container {
    box-sizing: border-box;
    padding: 32px 0 5px 32px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
    background-color: #FAFAFA;
  }
  .media-category {
    padding-left: 12px;
    padding-bottom: 16px;
  }
  button.category-btn {
    box-sizing: border-box;
    margin-right: 16px;
    border-radius: 4px;
    border: 1px solid#D4D4D4;
    width: 64px;
    height: 31px;
    font-size: 12px;
    color: #222222;
    background: #ffffff;
    &:hover {
      color: #E74362;
      border-color: #E74362;
    }
    //active 在hover之后防止被覆盖样式
    &.active {
      border-color: #E74362;
      color: #FFFFFF;
      background: #E74362;
    }
  }
  .media-list {
    padding-right: 32px;
  }
  .media-list-item {
    cursor: pointer;
    transition: all 0.2s;
    border: 1px solid transparent;
    &:hover {
      background-color:#EDEDED;

    }
    &.active {
      border-color: #E74362;
    }
  }
  .media-list-card {
    padding: 12px;
  }
  // 勾选图标
  .media-list-multiple {
    position: absolute;
    left: 20px;
    top: 20px;
    z-index: 9;
    border-radius: 20px;
    background-color: #ffffff;
    opacity: 0;
    &.checked {
      opacity: 1;
    }
  }
  // 勾选框
  .media-list-checkbox {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 6;
    /deep/.el-checkbox__input.is-checked .el-checkbox__inner,
    /deep/.el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: #E74362!important;
      border-color: #E74362!important;
      opacity: 1;
    }
    /deep/.el-checkbox__label {
      display: none;
    }
    /deep/.el-checkbox__input {
      margin-left: 20px;
      margin-top: 20px;
      border-radius: 4px;
      width: 20px;
      height: 20px;
      .el-checkbox__inner {
        opacity: 0;
        width: inherit;
        height: inherit;
        border: none;
        background-color: #E74362!important;
        &::after {
          left: 7px;
          top: 3px;
          width: 4px;
          height: 8px;
          border-width: 2px;
        }
      }
    }
  }
  
  .media-image {
    object-fit: cover;
    box-sizing: border-box;
    border: 1px solid #D4D4D4;
    width: 150px;
    height: 150px;
    display: block;
  }

  // 右侧预览区域
  .media-preview {
    flex-shrink: 0;
    box-sizing: border-box;
    z-index: 1;
    // width: 318px;
    width: 0;
    height: 100%;
    background: #fff;
    transition: all 0.3s;
    // box-shadow: -1px 0 8px 0 rgba(0,0,0,0.12);
    border-left: 1px solid #D4D4D4;
    &.show {
      width: 318px;
    }

    .media-preview-inner {
      padding: 24px 24px 0;
    }
    .media-preview-cover-outer {
      &:hover {
        .media-preview-cover-mask {
          opacity: 1;
        }
      }
    }
    .media-preview-cover {
      display: block;
      box-sizing: border-box;
      border: 1px solid #D4D4D4;
      width: 270px;
      height: 270px;
    }
    .media-preview-cover-mask {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.45);
    }
    button.media-edit,
    button.media-del {
        border-radius: 16px;
        border: none;
        width: 270px;
        height: 32px;
        font-size: 14px;
        color: #FFFFFF;
    }
    button.media-edit {
        background: #E74362;
    }
    button.media-del {
        background: #CCCCCC;
    }
  }

  // 编辑弹窗
  /deep/.el-dialog__body {
    padding-top: 20px;
    padding-bottom: 32px;
  }
  /deep/.el-dialog__footer {
    padding-bottom: 32px;
  }

  button.dialog-form-upload {
    box-sizing: border-box;
    border: 1px solid #D4D4D4;
    border-radius: 18px;
    width: 106px;
    height: 36px;
    line-height: 34px;
    background: #FAFAFA;
  }

  /deep/.el-upload-list {
    display: none;
  }
  .upload-list {
    height: 24px;
    line-height: 24px;
    .remove-picture {
      vertical-align:middle;
      padding: 3px;
    }
  }

  .media-resource-area {
    flex-shrink: 0;
    box-sizing: border-box;
    width: 57%;
    height: 468px;
    border: 1px solid #d4d4d4;
    background: #FAFAFA;
  }
  .media-resource-area-img {
    width: 192px;
    height: 192px;
  }
  .media-resource-area-bottom {
    border-top: 1px solid #D4D4D4;
    box-sizing: border-box;
    height: 77px;
    padding: 0 24px;
    width: 100%;

    .media-video {
      position: relative;
      box-sizing: border-box;
      margin-left: 27px;
      .media-video-small-img {
        width: calc(100% / 8);
        height: 42px;
        object-fit: cover;
      }
      .time-tips {
        position: absolute;
        left: 20%;
        top: 0;
        width: 1px;
        height: 42px;
        background-color: #E74362;
        .time-text {
          position: absolute;
          top: -25px;
          left: 50%;
          transform: translate(-50%, 0);
          border: 1px solid #D4D4D4;
          border-radius: 2px;
          width: 46px;
          height: 18px;
          line-height: 18px;
          text-align: center;
          background-color: #EDEDED;
        }
      }
      // 进度条
      /deep/.el-slider__bar {
        background-color: #E74362;
      }
      /deep/.el-slider__button {
        border: 1px solid #FFFFFF;
        width: 14px;
        height: 14px;
        background: #E74362;
      }
    }
  }
  .media-resource-input {
    padding-left: 24px;
  }
  .media-edit-input.height36 {
    height: 36px;
    line-height: 36px;
  }
  .media-edit-input.is-disabled {
    line-height: 34px;
    /deep/input {
      height: 34px;
      line-height: 34px;
    }
  }
  /deep/.el-select__tags-text {
    max-width: 100px;
  }
  // /deep/.el-select__tags {
  //   top: 4px;
  //   left: 0px;
  //   transform: translateY(0);
  //   .el-tag {
  //     margin-left: 14px;
  //   }
  // }

  .media-resource-select {
    box-sizing: border-box;
    border: 1px solid #D1D1D1;
    width: 100%;
    min-height: 34px;
    max-height: 84px;
    overflow-y: auto;
    /deep/.el-input .el-input__inner{
      max-height: 80px;
      min-height: 36px;
    }
    /deep/ .el-tag {
        border-radius: 4px;
        background: #E74362;
        height: 26px;
        line-height: 24px;
        font-size: 12px;
        margin-bottom: 4px;
        margin-top: 4px;
        margin-left: 14px;
        .el-select__tags-text {
            color: #FFFFFF;
        }
        .el-tag__close {
            color: #E74362;
            background: #FFFFFF;
        }
    }
  }
  .upload-new-select {
    /deep/.el-select__tags {
      .el-tag {
        margin-left: 14px;
      }
    }
  }

  // 图片
  .media-resource-cropper {
    flex-shrink: 0;
    width: 57%;
    padding-top: 24px;
    height: 468px;
    border: 1px solid #d4d4d4;
    // background: #FAFAFA;
    background-color: #FFFFFF;
    // 裁切范围
    .media-resource-cropper-wrapper {
      width: 100%;
      height: 360px;
    }
    .media-resource-cropper-img {
      width: 100%;
      height: 360px;
      object-fit: contain;
    }
    // 裁切工具
    .media-resource-cropper-tools {
      display: flex;
      align-items: center;
      border-top: 1px solid #D4D4D4;
      width: 100%;
      height: 78px;
      .item {
        flex:1;
        height: 100%;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          .cropper-icon,
          .cropper-name {
            color: #E74362;
          }
        }
        &.active {
          background-color: #E74362;
          .cropper-name {
            color: #FFFFFF;
          }
        }
        &.disabled {
          .cropper-icon,
          .cropper-name {
            color: #a1a1a1;
          }
        }
      }
    }
    // 取消完成
    .media-resource-cropper-done {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      box-sizing: border-box;
      padding-right: 3px;
      width: 100%;
      height: 32px;
      background-color: #EDEDED;
      .btn {
        padding: 7px 12px;
        font-size: 12px;
        line-height: 18px;
        cursor: pointer;
      }
      .cancel {
        color: #A1A1A1;
      }
      .done {
        color: #E74362;
      }
    }
  }

  .upload-progress-item {
    .media-image {
      background-color: #EDEDED;
      border: 1px solid #D4D4D4;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .media-progress {
      display: flex;
      align-items: center;
      position: relative;
      width: 70px;
      height: 6px;
      background-color: #D8D8D8;
      border-radius: 3px;
      .media-progress-inner {
        margin-left: 1px;
        background: #E74362;
        border-radius: 4px;
        height: 4px;
        width: 20%;
      }
    }
  }

  // 隐藏上传按钮
  .upload-hide {
    width: 0;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  /deep/.cropper-wrap-box,
  /deep/.cropper-canvas {
    background-color: #FFFFFF;
  }
</style>